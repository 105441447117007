import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { lotteryActions } from '../../_actions';
import { HistTable } from '../../components/HistTable';
import IVSVideo from '../../components/IVS/IVSVideo';
import ball0 from '../../_images/0.png';
import ball1 from '../../_images/1.png';
import ball2 from '../../_images/2.png';
import ball3 from '../../_images/3.png';
import ball4 from '../../_images/4.png';
import ball5 from '../../_images/5.png';
import ball6 from '../../_images/6.png';
import ball7 from '../../_images/7.png';
import ball8 from '../../_images/8.png';
import ball9 from '../../_images/9.png';
import { isMobile } from 'react-device-detect';
import Countdown, { zeroPad } from 'react-countdown';


const Home = () => {
    const dispatch = useDispatch();
    const currInfo = useSelector(state => state.lottery.currInfo);
    const tokenInfo = useSelector(state => state.lottery.streamToken);
    //const resultHist = useSelector(state => state.lottery.resultHist);

    const isRenewToken = false;
    const [connection, setConnection] = useState(null);
    const [isStreaming, setIsStreaming] = useState(false);
    const [currResult, setCurrResult] = useState('');

    useEffect(
        () => {
            async function getCurrrInfo() {
                dispatch(lotteryActions.getCurrentInfo());
            }
            getCurrrInfo();
        }, []
    )

    useEffect(
        () => {
            async function setStreamStat() {
                if (currInfo) {
                    if (currInfo.chStat === 'ACT' && currInfo.status === 'OPEN') {
                        setIsStreaming(true);
                        setCurrResult(currInfo.tempResult);
                    }
                    else
                        setIsStreaming(false);
                }
            }
            setStreamStat();
        }, [currInfo]
    )

    useEffect(
        () => {
            async function getToken() {
                if (isStreaming) {
                    //if (tokenInfo) {
                    //    var _currDt = new Date();
                    //    var _expDt = new Date(tokenInfo.exp);
                    //    var _bool = _expDt < _currDt ? true : false;
                    //    //console.log('token expiration status: ', _bool) 
                    //}

                    //if (!tokenInfo || _bool)
                    dispatch(lotteryActions.generateStreamToken());
                }
            }
            getToken();
        }, [isStreaming]
    )

    useEffect(
        () => {
            async function renewToken() {
                if (isRenewToken) {
                    dispatch(lotteryActions.generateStreamToken());
                }
            }
            renewToken();
        }, [isRenewToken]
    )

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const signalR = require("@microsoft/signalr");
            const newConnection = new signalR.HubConnectionBuilder()
                .withUrl(`${process.env.REACT_APP_LOTTERY_API}/Hub/Marble`)
                .withAutomaticReconnect()
                .build();

            setConnection(newConnection);
        }
    }, []);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (connection) {
                connection.start()
                    .then(result => {
                        connection.on('ReceiveAnnounceStarted', (result) => {
                            //play streaming
                            setIsStreaming(true);
                        });

                        connection.on('ReceiveAnnounceEnded', (result) => {
                            setIsStreaming(false);
                            getResultHist();
                            setCurrResult('');
                        });

                        connection.on('ReceiveLotteryResult', (result) => {
                            //console.log('result: ', result)
                            //console.log('result 2: ', result.Result);
                            setCurrResult(result.Result);
                        });
                    })
                    .catch(e => console.log('ReceiveAnnounceStarted Connection failed: ', e));
            }
        }
    }, [connection]);

    async function getResultHist() {
        var _reqInfo = {
            pageNum: 1,
            recordCount: 15
        }
        dispatch(lotteryActions.getResultHist(_reqInfo));
    }

    function getFormattedDate(dateString) {
        var delimiter = '/';
        var d = new Date(dateString);
        return d.getMonth() + delimiter + d.getDate() + delimiter + d.getFullYear() + ' ' + (d.getUTCHours() + 7) + ':' + ('00' + d.getMinutes()).slice(-2) + ' GMT+7';
    }

    function getResultImg(resultNum) {
        switch (resultNum) {
            case '0':
                return (<img src={ball0} className="h-12" />);
            case '1':
                return (<img src={ball1} className="h-12" />);
            case '2':
                return (<img src={ball2} className="h-12" />);
            case '3':
                return (<img src={ball3} className="h-12" />);
            case '4':
                return (<img src={ball4} className="h-12" />);
            case '5':
                return (<img src={ball5} className="h-12" />);
            case '6':
                return (<img src={ball6} className="h-12" />);
            case '7':
                return (<img src={ball7} className="h-12" />);
            case '8':
                return (<img src={ball8} className="h-12" />);
            case '9':
                return (<img src={ball9} className="h-12" />);
        }
    }

    const renderer = ({ days, hours, minutes, seconds }) => {
        const dayTime = days * 24;
        return <span className="text-4xl animate-pulse font-medium">{zeroPad(hours + dayTime)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
    };


    return (
        <div className="">
            <div className="relative mt-4">
                <div className="absolute right-0 left-0 px-2">
                    <div className="max-w-3xl m-auto">
                        <div className="sm:col-span-2 px-2">
                            <div className={` ${isMobile ? 'h-52' : 'h-96'}  ${isStreaming ? '' : 'bg-black'} rounded-md flex justify-center`}>
                                <div className="relative h-full">
                                    {
                                        isStreaming &&
                                        tokenInfo &&
                                        tokenInfo.token &&
                                        <>
                                            <div className="pl-2 pt-1 w-full flex absolute top-0 text-gray-400 text-xs animate-pulse">
                                                Period: {currInfo ? currInfo.periodNo : ''}
                                            </div>
                                            <IVSVideo token={tokenInfo.token} isRenewToken={isRenewToken} />
                                        </>
                                    }
                                    {
                                        !isStreaming &&
                                        currInfo &&
                                        <div className="h-full flex flex-col items-center justify-center">
                                            <div className="text-white text-xs sm:text-sm">
                                                <span className="flex flex-col items-center justify-center">
                                                    <span>Announcement will start at {getFormattedDate(currInfo.endDtm)}</span>
                                                    <span className="mt-8 text-sm font-medium animate-pulse">WAITING</span>
                                                    <Countdown date={currInfo.endDtm} renderer={renderer} className="" />
                                                </span>
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                        <div className="px-2 py-2">
                            {
                                isStreaming &&
                                <div className="mt-8">
                                    <div className="">
                                        <div className="font-bold text-sm w-full">
                                            ANNOUNCEMENT RESULT
                                        </div>
                                        <div className="mt-1 columns-4 gap-1 text-center font-bold text-4xl">
                                            <div className="bg-white rounded-md border-2 h-16">
                                                <span className="flex justify-center items-center h-full">{currResult && currResult.length > 0 ? getResultImg(currResult[0]) : ''}</span>
                                            </div>
                                            <div className="bg-white rounded-md border-2 h-16">
                                                <span className="flex justify-center items-center h-full">{currResult && currResult.length > 1 ? getResultImg(currResult[1]) : ''}</span>
                                            </div>
                                            <div className="bg-white rounded-md border-2 h-16">
                                                <span className="flex justify-center items-center h-full">{currResult && currResult.length > 2 ? getResultImg(currResult[2]) : ''}</span>
                                            </div>
                                            <div className="bg-white rounded-md border-2 h-16">
                                                <span className="flex justify-center items-center h-full">{currResult && currResult.length > 3 ? getResultImg(currResult[3]) : ''}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="sm:col-span-3 px-2 mt-8 mb-32">
                            <div className="font-bold text-sm w-full">
                                RESULT HISTORY
                            </div>
                            <div className="text-xs sm:text-sm">
                                <HistTable />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Helmet>‍
                <title>Marble Race 4D Lottery</title>‍
                <meta name="description" content="Marble Race 4D Lottery | Marble Race | Live Draw | Online Lottery | 4D Lottery" />‍
            </Helmet>‍
        </div >
    );
}

export default Home;

{

    //<div className="px-2 py-2 hidden sm:block">

    //    {
    //        lastResult &&
    //        lastResult.result &&
    //        <div className="mt-16">
    //            <div className="">
    //                <div className="font-bold text-lg w-full">
    //                    PREVIOUS RESULT [{lastResult.periodNo}]
    //                </div>
    //                <div className="mt-1 columns-4 gap-1 text-center font-bold text-4xl py-2">
    //                    <div className="bg-white rounded-md border-2 h-16">
    //                        <span className="flex justify-center items-center h-full">{lastResult.result[0]}</span>
    //                    </div>
    //                    <div className="bg-white rounded-md border-2 h-16">
    //                        <span className="flex justify-center items-center h-full">{lastResult.result[1]}</span>
    //                    </div>
    //                    <div className="bg-white rounded-md border-2 h-16">
    //                        <span className="flex justify-center items-center h-full">{lastResult.result[2]}</span>
    //                    </div>
    //                    <div className="bg-white rounded-md border-2 h-16">
    //                        <span className="flex justify-center items-center h-full">{lastResult.result[3]}</span>
    //                    </div>
    //                </div>
    //            </div>
    //        </div>
    //    }
    //</div>
}